export const LOCAL_PARTNER_REQUEST = 'LOCAL_PARTNER_REQUEST'
export const UNIDENTIFIED_LOCAL_PARTNER = 'UNIDENTIFIED_LOCAL_PARTNER'
export const LOCAL_PARTNER_IDENTIFIED = 'LOCAL_PARTNER_IDENTIFIED'

export const PARTNER_IDENTITY_REQUEST = 'PARTNER_IDENTITY_REQUEST'
export const PARTNER_IDENTITY_ERROR = 'PARTNER_IDENTITY_ERROR'
export const PARTNER_IDENTITY_SUCCESS = 'PARTNER_IDENTITY_SUCCESS'
export const PARTNER_IDENTITY_RESET = 'PARTNER_IDENTITY_RESET'

export const SAVE_LOCAL_PARTNER_IDENTITY = 'SAVE_LOCAL_PARTNER_IDENTITY'

export const LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST'
export const SOLVE_URL_REQUEST = 'SOLVE_URL_REQUEST'
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR'
export const LOGIN_USER_ERROR_RESET = 'LOGIN_USER_ERROR_RESET'
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS'
export const LOGIN_USER_WARNING = 'LOGIN_USER_WARNING'

export const SAVE_LOCAL_USER = 'SAVE_LOCAL_USER'
export const LOCAL_USER_REQUEST = 'LOCAL_USER_REQUEST'
export const USER_NOT_LOGGED = 'USER_NOT_LOGGED'
export const USER_ALREADY_LOGGED = 'USER_ALREADY_LOGGED'
export const INCOMPLETE_REGISTER_CONTEXT = 'INCOMPLETE_REGISTER_CONTEXT'

export const LOGOUT_USER_REQUEST = 'LOGOUT_USER_REQUEST'
export const LOGOUT_USER_FAILED = 'LOGOUT_USER_FAILED'
export const LOGOUT_USER_COMPLETE = 'LOGOUT_USER_COMPLETE'

export const EXPIRED_ACCESS_TOKEN = 'EXPIRED_ACCESS_TOKEN'

export const NEW_ACCESS_TOKEN_REQUEST = 'NEW_ACCESS_TOKEN_REQUEST'
export const NEW_ACCESS_TOKEN_FAILED = 'NEW_ACCESS_TOKEN_FAILED'
export const NEW_ACCESS_TOKEN_COMPLETE = 'NEW_ACCESS_TOKEN_COMPLETE'

export const PUT_APP_API_AUTHORIZATION = 'PUT_APP_API_AUTHORIZATION'

export const LOAD_THEME_LOCAL_SERVICE = 'LOAD_THEME_LOCAL_SERVICE'

export const SAVE_ROLES = 'SAVE_ROLES'

export const SET_LOADING = 'SET_LOADING'

export const APP_START_OR_LOGIN = 'APP_START_OR_LOGIN'

export const SET_PARTNER_PARAMETERS_REQUEST = 'SET_PARTNER_PARAMETERS_REQUEST'
export const SET_PARTNER_PARAMETERS_FAILED = 'SET_PARTNER_PARAMETERS_FAILED'
export const SET_PARTNER_PARAMETERS_COMPLETE = 'SET_PARTNER_PARAMETERS_COMPLETE'

export const UPDATE_PARTNER_PARAMS_REQUEST = 'UPDATE_PARTNER_PARAMS_REQUEST'
export const UPDATE_PARTNER_PARAMS_FAILED = 'UPDATE_PARTNER_PARAMS_FAILED'
export const UPDATE_PARTNER_PARAMS_COMPLETE = 'UPDATE_PARTNER_PARAMS_COMPLETE'

export const SYSTEM_PARAMETERS_REQUEST = 'SYSTEM_PARAMETERS_REQUEST'
export const SYSTEM_PARAMETERS_FAILED = 'SYSTEM_PARAMETERS_FAILED'
export const SYSTEM_PARAMETERS_COMPLETE = 'SYSTEM_PARAMETERS_COMPLETE'

export const OPEN_CHANGE_PASSWORD_MODAL = 'USER_OPEN_CHANGE_PASSWORD_MODAL'
export const BLOCK_CHANGE_PASSWORD_MODAL = 'USER_BLOCK_CHANGE_PASSWORD_MODAL'
