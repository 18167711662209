import * as types from 'types/partner'

import * as actionTypes from './action-types'

export const checkLocalPartnerRequest = (): any => ({
  type: actionTypes.LOCAL_PARTNER_REQUEST,
})

export const loadThemeLocalService = (payload: any): any => ({
  type: actionTypes.LOAD_THEME_LOCAL_SERVICE,
  payload,
})

export const openChangePasswordModal = (): any => ({
  type: actionTypes.OPEN_CHANGE_PASSWORD_MODAL,
})

export const blockChangePasswordModal = (): any => ({
  type: actionTypes.BLOCK_CHANGE_PASSWORD_MODAL,
})

export const checkLocalPartnerNotFound = (): any => ({
  type: actionTypes.UNIDENTIFIED_LOCAL_PARTNER,
})

export const checkLocalPartnerFound = (
  payload: types.PartnerIdentity,
): any => ({
  type: actionTypes.LOCAL_PARTNER_IDENTIFIED,
  payload,
})

export const fetchPartnerRequest = (): any => ({
  type: actionTypes.PARTNER_IDENTITY_REQUEST,
})

export const fetchPartnerError = (): any => ({
  type: actionTypes.PARTNER_IDENTITY_ERROR,
})

export const fetchPartnerSuccess = (payload: types.PartnerState): any => ({
  type: actionTypes.PARTNER_IDENTITY_SUCCESS,
  payload,
})

export const saveLocalPartner = (payload: types.PartnerIdentityState): any => ({
  type: actionTypes.SAVE_LOCAL_PARTNER_IDENTITY,
  payload,
})

export const solveUrlRequest = (payload: types.LoginPayload) => ({
  type: actionTypes.SOLVE_URL_REQUEST,
  payload,
})

export const loginUserRequest = (payload: types.LoginPayload) => ({
  type: actionTypes.LOGIN_USER_REQUEST,
  payload,
})

export const loginUserError = (payload?: unknown): any => ({
  type: actionTypes.LOGIN_USER_ERROR,
  payload,
})

export const loginUserErrorReset = (payload?: unknown): any => ({
  type: actionTypes.LOGIN_USER_ERROR_RESET,
  payload,
})

export const loginUserSuccess = (payload: types.PartnerUser): any => ({
  type: actionTypes.LOGIN_USER_SUCCESS,
  payload,
})

export const loginUserWarning = (payload: types.MfaCode): any => ({
  type: actionTypes.LOGIN_USER_WARNING,
  payload,
})

export const saveLocalPartnerUser = (payload: types.PartnerUser): any => ({
  type: actionTypes.SAVE_LOCAL_USER,
  payload,
})

export const localUserRequest = (): any => ({
  type: actionTypes.LOCAL_USER_REQUEST,
})

export const userNotLogged = (): any => ({
  type: actionTypes.USER_NOT_LOGGED,
})

export const userAlreadyLogged = (payload: types.PartnerUser): any => ({
  type: actionTypes.USER_ALREADY_LOGGED,
  payload,
})

export const logoutUserRequest = (payload?: any): any => ({
  type: actionTypes.LOGOUT_USER_REQUEST,
  payload,
})

export const logoutUserFailed = (payload?: any): any => ({
  type: actionTypes.LOGOUT_USER_FAILED,
  payload,
})

export const logoutUserComplete = (payload?: any): any => ({
  type: actionTypes.LOGOUT_USER_COMPLETE,
  payload,
})

export const expiredAccessToken = (): any => ({
  type: actionTypes.EXPIRED_ACCESS_TOKEN,
})

export const newAccessTokenRequest = (): any => ({
  type: actionTypes.NEW_ACCESS_TOKEN_REQUEST,
})

export const newAccessTokenFailed = (): any => ({
  type: actionTypes.NEW_ACCESS_TOKEN_FAILED,
})

export const newAccessTokenComplete = (payload: any): any => ({
  type: actionTypes.NEW_ACCESS_TOKEN_COMPLETE,
  payload,
})

export const putAppApiAuthorization = (payload: any): any => ({
  type: actionTypes.PUT_APP_API_AUTHORIZATION,
  payload,
})

export const updateRoles = (payload: any): any => ({
  type: actionTypes.SAVE_ROLES,
  payload,
})

export const setLoading = (payload: boolean): any => ({
  type: actionTypes.SET_LOADING,
  payload,
})

export const onAppStartOrLogin = (deployment?: boolean) => ({
  type: actionTypes.APP_START_OR_LOGIN,
  payload: {
    deployment,
  },
})

export const setPartnerParametersFailed = () => ({
  type: actionTypes.SET_PARTNER_PARAMETERS_FAILED,
})

export const setPartnerParametersComplete = (
  payload: types.PartnerParameters,
) => ({
  type: actionTypes.SET_PARTNER_PARAMETERS_COMPLETE,
  payload,
})

export const updatePartnerParametersRequest = (payload: any) => ({
  type: actionTypes.UPDATE_PARTNER_PARAMS_REQUEST,
  payload,
})

export const updatePartnerParametersFailed = () => ({
  type: actionTypes.UPDATE_PARTNER_PARAMS_FAILED,
})

export const updatePartnerParametersComplete = (payload: any) => ({
  type: actionTypes.UPDATE_PARTNER_PARAMS_COMPLETE,
  payload,
})

export const systemPartnerFailed = () => ({
  type: actionTypes.SYSTEM_PARAMETERS_FAILED,
})

export const systemPartnerComplete = (payload: any) => ({
  type: actionTypes.SYSTEM_PARAMETERS_COMPLETE,
  payload,
})
