// 3rd parties
import { combineReducers } from 'redux'

// Application
import { appApi } from '../../apis'
import { getSubdomain } from '../../common/subdomain'
import * as types from '../../types/partner'
// Local
import { PARTNER_ID } from '../../apis/constants'
import { Action } from '../../types/action'
import * as actionTypes from './action-types'

type Types = 'deploy' | 'others' | ''

enum MESSAGES {
  'deploy' = 'O site está em manutenção. Desculpem-nos pelo transtorno, estamos trabalhando para melhor atendê-los.',
  'others' = 'Em razão dos feriados de final de ano, a Bolsa de Valores de São Paulo não funcionará nos 24 e 31, portanto Investimentos e Resgates para estes dias podem somente ser agendados para o próximo dia útil.',
}

/**
 * function to activate the deploy banner, when call it, use localPartner.updateCurrent to update localStorage too
 * @param {Types} type {Types} - type
 * @param {boolean} show {boolean} - show
 */

export const getTypeAlert = (type: Types, show: boolean) => {
  if (!type) {
    return {
      title: '',
      show,
      message: '',
    }
  }

  return {
    title: 'Atenção!',
    show,
    message: MESSAGES[type],
  }
}

const partnerValues = {
  document: '90400888000142',
  id: PARTNER_ID,
  name: 'Escrow',
  subdomain: getSubdomain(),
  theme: {
    mode: 'light',
    preferences: {
      login: 'logoUrl',
    },
    name: 'Escrow',
    primaryColor: '#CC0000',
    logoUrl: '/santander.png',
    secondaryColor: '#ff0505',
  },
  url: 'https://santander.toroescrow.com.br',
}

export const defaultPartnerState: types.PartnerState | any = {
  identity: partnerValues,
  user: {
    authenticated: false,
    authenticating: false,
    loginFailed: false,
    loading: false,
    error: null,
    accessToken: null,
    idToken: null,
    refreshToken: null,
    roles: null,
    email: null,
    context: true,
    isBlockedUrl: null,
  },
}

export function identity(
  state = defaultPartnerState.identity,
  action: Action,
): types.PartnerIdentity {
  switch (action.type) {
    case actionTypes.APP_START_OR_LOGIN:
      const { deployment = false } = action.payload

      return {
        ...state,
        alert: getTypeAlert('deploy', deployment),
      }
    case actionTypes.LOGIN_USER_SUCCESS:
    case actionTypes.USER_ALREADY_LOGGED:
      return {
        ...state,
      }

    case actionTypes.PARTNER_IDENTITY_REQUEST: {
      return {
        ...state,
        identifying: true,
      }
    }

    case actionTypes.LOAD_THEME_LOCAL_SERVICE: {
      const { payload } = action

      return {
        ...state,
        theme: payload,
      }
    }

    case actionTypes.INCOMPLETE_REGISTER_CONTEXT: {
      return {
        ...state,
        isErrorIdentifyContext: true,
      }
    }

    case actionTypes.LOCAL_PARTNER_IDENTIFIED: {
      const { payload } = action as types.PartnerPayload

      return {
        ...state,
        identifying: false,
        identified: true,
        unidentified: false,
        isErrorIdentifyContext: false,
        ...payload,
      }
    }

    case actionTypes.PARTNER_IDENTITY_SUCCESS: {
      const { payload } = action as types.PartnerPayload

      return {
        identifying: false,
        identified: true,
        unidentified: false,
        isErrorIdentifyContext: false,
        ...payload,
      }
    }

    case actionTypes.PARTNER_IDENTITY_ERROR: {
      return {
        ...state,
        identifying: false,
        unidentified: true,
      }
    }

    case actionTypes.PARTNER_IDENTITY_RESET: {
      return defaultPartnerState.identity
    }

    case actionTypes.UNIDENTIFIED_LOCAL_PARTNER:
    default:
      return state
  }
}

export function user(
  state = defaultPartnerState.user,
  action: Action,
): types.PartnerUser {
  switch (action.type) {
    case actionTypes.LOGIN_USER_REQUEST: {
      return {
        ...state,
        authenticating: true,
      }
    }

    case actionTypes.LOGIN_USER_WARNING: {
      const { payload } = action as types.PartnerPayload

      return {
        ...state,
        mfaCode: payload,
        warning: true,
        authenticated: false,
        authenticating: false,
      }
    }

    case actionTypes.LOGIN_USER_ERROR: {
      const { payload } = action as types.PartnerPayload

      return {
        ...state,
        authenticated: false,
        authenticating: false,
        loginFailed: true,
        error: payload,
      }
    }

    case actionTypes.LOGIN_USER_ERROR_RESET: {
      return {
        ...state,
        loginFailed: false,
        error: null,
        context: true,
      }
    }

    case actionTypes.USER_NOT_LOGGED: {
      return {
        ...state,
        authenticated: false,
        authenticating: false,
      }
    }

    case actionTypes.OPEN_CHANGE_PASSWORD_MODAL: {
      return {
        ...state,
        openChangePasswordModal: true,
      }
    }

    case actionTypes.BLOCK_CHANGE_PASSWORD_MODAL: {
      return {
        ...state,
        openChangePasswordModal: false,
        roles: {
          ...state.roles,
          forceNewPassword: false,
        },
      }
    }

    case actionTypes.LOGIN_USER_SUCCESS:
    case actionTypes.USER_ALREADY_LOGGED:
      const { payload } = action as types.PartnerUserPayload

      if (!payload?.roles?.status) {
        payload.roles = {
          contractRoles: [],
          document: '',
          profiles: [],
          userName: '',
          forceNewPassword: false,
          status: 'active',
          role: 'guest',
          name: getSubdomain(),
        }
      }

      return {
        ...state,
        authenticated: true,
        authenticating: false,
        context: true,
        loginFailed: null,
        ...payload,
      }

    case actionTypes.EXPIRED_ACCESS_TOKEN: {
      return {
        ...state,
        authenticating: true,
      }
    }

    case actionTypes.LOGOUT_USER_FAILED:
    case actionTypes.LOGOUT_USER_COMPLETE:
    case actionTypes.NEW_ACCESS_TOKEN_FAILED: {
      const { payload } = action
      appApi.defaults.headers.authorization = ''
      return {
        ...defaultPartnerState.user,
        ...payload,
      }
    }

    case actionTypes.NEW_ACCESS_TOKEN_COMPLETE: {
      const { payload } = action as any

      return {
        ...state,
        ...payload,
        authenticating: false,
      }
    }

    case actionTypes.SAVE_ROLES: {
      return {
        ...state,
        roles: {
          ...state?.roles,
          ...action.payload,
        },
      }
    }

    case actionTypes.SET_LOADING: {
      return {
        ...state,
        loading: action.payload,
      }
    }

    case actionTypes.LOCAL_USER_REQUEST:
    case actionTypes.SAVE_LOCAL_USER:
    default:
      return state
  }
}

export const partnerReducer = combineReducers({
  identity,
  user,
})
