const {
  REACT_APP_API_KEY,
  REACT_APP_API_ROOT,
  REACT_APP_PARTNER_ID,
  REACT_APP_DOCUMENT_API,
  REACT_APP_CONFIG_DOMAIN,
} = process.env

export const APP_DOCUMENT_API_ROOT = REACT_APP_DOCUMENT_API
export const APP_CONFIG_DOMAIN = REACT_APP_CONFIG_DOMAIN
export const APP_API_ROOT = REACT_APP_API_ROOT
export const API_KEY = REACT_APP_API_KEY

export const PARTNER_ID = REACT_APP_PARTNER_ID
