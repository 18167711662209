// 3rd parties
import axios, { AxiosResponse } from 'axios'
import * as apis from '../../apis'
import { APP_API_ROOT } from '../../apis/constants'
// Application
import * as types from '../../types/partner'

export interface IToken {
  idToken: string
  refreshToken: string
  accessToken: string
}

const localPartner = localPartnerService()

export const loginUserService = async (
  loginPayload: types.LoginPayload,
): Promise<AxiosResponse> => {
  const fetch = apis.proxyAuthApi()

  const { loginValues, reCaptchaToken } = loginPayload

  const data = {
    userName: loginValues.username,
    password: loginValues.password,
    mfaCode: loginValues?.mfaCode,
    isResend: loginValues?.resend,
  }

  return fetch.post('/login', data, {
    headers: { 'g-recaptcha-response': reCaptchaToken },
  })
}

export const logoutUserService = async (user: any): Promise<AxiosResponse> => {
  const fetch = apis.proxyAuthApi(user.accessToken)

  const data = { accessToken: user.accessToken }

  return fetch.post('/logout', data)
}

export const sendMfaCode = async (
  user: any,
  operation = 'escrow',
): Promise<any> => {
  const fetch = apis.proxyAuthApi(user.accessToken)

  return fetch.put('/otp-transaction', { operation })
}

export const putChangePassword = async (values: any): Promise<any> => {
  try {
    const { user } = localPartner.getCurrent()
    const fetch = apis.proxyAuthApi(user.accessToken)

    const data = {
      password: values.currentPassword,
      newPassword: values.newPassword,
    }

    return fetch.put('/change-password', data, {
      headers: { 'g-recaptcha-response': values.reCaptchaToken },
    })
  } catch (error) {
    return apis.handleErrorResponse(error)
  }
}

export function localPartnerService() {
  const clearCache = () => sessionStorage.removeItem('partner')

  const setCurrent = (partner: types.PartnerIdentityState) => {
    sessionStorage.setItem('partner', JSON.stringify(partner))
  }

  const updateCurrent = (
    newPartner: types.PartnerIdentityState | types.PartnerUserState,
  ) => {
    const currentPartner = getCurrent()

    const updatedCurrent = { ...currentPartner, ...newPartner }
    sessionStorage.setItem('partner', JSON.stringify(updatedCurrent))
  }

  const getCurrent = () => {
    const partner = sessionStorage.getItem('partner')

    if (!partner) {
      return null
    }

    return JSON.parse(partner)
  }

  const removeUser = () => {
    const { identity } = getCurrent()

    const partner = { identity }

    sessionStorage.setItem('partner', JSON.stringify(partner))
  }

  return { clearCache, setCurrent, getCurrent, updateCurrent, removeUser }
}

export const fetchForgotPasswordEmail = async (
  email: string,
  reCaptchaToken: string,
) => {
  try {
    const fetch = apis.proxyAuthApi()
    const data = { username: email }

    const url = '/forgot-password'
    const callApi: AxiosResponse = await fetch.put(url, data, {
      headers: { 'g-recaptcha-response': reCaptchaToken },
    })

    return apis.handleSuccessResponse(callApi)
  } catch (error) {
    return apis.handleErrorResponse(error)
  }
}

export const fetchForgotPasswordConfirm = async (
  password: string,
  username: string,
  verificationCode: string,
  reCaptchaToken: string,
) => {
  try {
    const fetch = apis.proxyAuthApi()
    const data = { newPassword: password, username }

    const url = `/forgot-password/${verificationCode}`

    const callApi: AxiosResponse = await fetch.put(url, data, {
      headers: { 'g-recaptcha-response': reCaptchaToken },
    })

    return apis.handleSuccessResponse(callApi)
  } catch (error) {
    return apis.handleErrorResponse(error)
  }
}

export const fetchRefreshToken = async () => {
  const {
    user: { refreshToken, accessToken },
  } = localPartner.getCurrent()

  const fetch = apis.proxyAuthApi(accessToken)

  const url = '/refresh-token'
  const data = { refreshToken }

  return fetch.post(url, data)
}

export const fetchUserInfo = async () => {
  const partnerStorage = localPartner.getCurrent()
  const accessToken = partnerStorage?.user?.accessToken

  if (!accessToken) {
    throw new Error('No access token')
  }

  const fetch = apis.proxyAuthApi(accessToken, { refresh: true })
  const endpoint = '/user-info'

  const response = await fetch.get(endpoint)

  return response.data
}

export const fetchPartnerParameters =
  async (): Promise<types.PartnerParameters> => {
    const defaultParameters = {
      charge: false,
      indicativeRates: false,
      discretionaryManagement: false,
    }

    try {
      const partnerId = localPartner.getCurrent()?.identity?.id
      const endpoint = `/partners/${partnerId}/parameters`

      const response = await apis.appApi.get(endpoint)

      return (response.data || defaultParameters) as types.PartnerParameters
    } catch {
      return defaultParameters
    }
  }

export const updatePartnerParameters = async (
  parameters: any,
): Promise<any> => {
  const partnerId = localPartner.getCurrent()?.identity?.id
  const endpoint = `/partners/${partnerId}/parameters`

  return apis.appApi.patch(endpoint, parameters)
}

export const fetchSystemParameters = async (): Promise<AxiosResponse> =>
  apis.appApi.get('/system/parameters')

export const createSession = async (
  authorization: IToken,
): Promise<AxiosResponse> =>
  axios.post(`${APP_API_ROOT}/escrow/security/session`, {
    ...authorization,
  })
