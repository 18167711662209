// Application

// Local
import { ROLES } from '@escrow/types-national/lib/roles'
import { Action } from 'types/action'
import * as actionTypes from './action-types'

export interface IUserGrants {
  currentRole: ROLES
  contractId: string
  grants: Array<{
    action: string[]
    attributes: string[]
    resource: string[]
    role: ROLES
  }>
  fetching: boolean
  initialLoading: boolean
  fetchError: boolean
}

export const defaultUserGrantsState = {
  currentRole: '',
  contractId: '',
  grants: [],
  fetching: false,
  initialLoading: true,
  fetchError: false,
}

export function userGrantsReducer(
  state = defaultUserGrantsState,
  action: Action,
): any {
  switch (action.type) {
    case actionTypes.GET_USER_GRANTS: {
      const { payload } = action

      return {
        ...state,
        fetching: true,
        fetchError: false,
        initialLoading: false,
        contractId: payload.contractId || '',
      }
    }

    case actionTypes.GET_USER_GRANTS_SUCCESS: {
      const { payload } = action

      return {
        ...state,
        currentRole: payload.role,
        grants: payload.grants,
        contractId: payload.contractId || '',
        fetching: false,
        initialLoading: false,
        fetchError: false,
      }
    }

    case actionTypes.GET_USER_GRANTS_FAILED: {
      const { payload } = action

      return {
        ...state,
        currentRole: '',
        contractId: payload?.contractId || '',
        fetchError: true,
        initialLoading: false,
        fetching: false,
      }
    }

    case actionTypes.CLEAR_USER_GRANTS: {
      return {
        ...defaultUserGrantsState,
      }
    }

    case actionTypes.SET_IDENTIFY_CONTEXT: {
      const { payload } = action
      const backoffice = payload.success
      return {
        ...state,
        context: {
          portal: !backoffice,
          backoffice,
          ...payload,
        },
      }
    }

    default: {
      return state
    }
  }
}
