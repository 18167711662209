export const DEFAULT_COUNTRY_REGISTRY = 'Brasil'
export const DEFAULT_CEP_REGISTRY = 100000

export const getLabelByStatus = (status: string | number, raw?: boolean) => {
  status = Number(status)
  switch (status) {
    case 1:
      return raw ? 'invited' : 'Convidado'

    case 2:
      return raw ? 'active' : 'Ativo'

    case 3:
      return raw ? 'pending' : 'Pendente'

    case 4:
      return 'Encerrado pela Toro'

    case 5:
      return raw ? 'declined' : 'Recusado'

    default:
      return 'Desconhecido'
  }
}

// Enum created to cover the OK status codes for Health Check
export enum validStatus {
  // 200 and 500 band status codes
  OK = 200,
  CREATED = 201,
  ACCEPTED = 202,
  NON_AUTHORITATIVE = 203,
  NO_CONTENT = 204,
  RESET_CONTENT = 205,
  PARTIAL_CONTENT = 206,
  INTERNAL_SERVER_ERROR = 500,
  NOT_IMPLEMENTED = 501,
  BAD_GATEWAY = 502,
  SERVICE_UNAVAILABLE = 503,
  GATEWAY_TIMEOUT = 504,
  SUPPORTED = 505,
}

export enum STATUS_ACCESS_MANAGEMENT {
  PENDING = 'pending',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  SUSPENDED = 'suspended',
}

export enum ESCROW_CONTEXTS {
  PORTAL = 'portal',
  BACKOFFICE = 'plataforma',
  BACKOFFICE_AUX = 'backoffice',
  BACKOFFICE_INTL = 'platform',
  BENEFICIARIO = 'beneficiario',
}

export enum USER_STATUS_TYPE {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}
