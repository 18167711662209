// 3rd parties
import { takeLatest } from 'redux-saga/effects'

// Local
import {
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  LOCAL_PARTNER_IDENTIFIED,
  LOGOUT_USER_REQUEST,
  USER_ALREADY_LOGGED,
  SAVE_LOCAL_USER,
  NEW_ACCESS_TOKEN_FAILED,
  NEW_ACCESS_TOKEN_COMPLETE,
  EXPIRED_ACCESS_TOKEN,
  APP_START_OR_LOGIN,
  UPDATE_PARTNER_PARAMS_REQUEST,
} from './action-types'
import {
  saveLocalPartnerUser,
  fetchLogin,
  localPartnerUser,
  fetchLogoutUser,
  newAccessTokenRequest,
  newAccessTokenFailed,
  putAppApiAuthorization,
  onAppStartOrLogin,
  updatePartnerParameters,
} from './operations'

export const partnerSaga = [
  {
    key: LOGIN_USER_REQUEST,
    sagasFn: takeLatest,
    action: fetchLogin,
  },
  {
    key: LOGIN_USER_SUCCESS,
    sagasFn: takeLatest,
    action: saveLocalPartnerUser,
  },
  {
    key: LOCAL_PARTNER_IDENTIFIED,
    sagasFn: takeLatest,
    action: localPartnerUser,
  },
  {
    key: SAVE_LOCAL_USER,
    sagasFn: takeLatest,
    action: putAppApiAuthorization,
  },
  {
    key: USER_ALREADY_LOGGED,
    sagasFn: takeLatest,
    action: putAppApiAuthorization,
  },
  {
    key: EXPIRED_ACCESS_TOKEN,
    sagasFn: takeLatest,
    action: newAccessTokenRequest,
  },
  {
    key: NEW_ACCESS_TOKEN_FAILED,
    sagasFn: takeLatest,
    action: newAccessTokenFailed,
  },
  {
    key: NEW_ACCESS_TOKEN_COMPLETE,
    sagasFn: takeLatest,
    action: putAppApiAuthorization,
  },
  {
    key: LOGOUT_USER_REQUEST,
    sagasFn: takeLatest,
    action: fetchLogoutUser,
  },
  {
    key: UPDATE_PARTNER_PARAMS_REQUEST,
    sagasFn: takeLatest,
    action: updatePartnerParameters,
  },
  {
    key: LOGIN_USER_SUCCESS,
    sagasFn: takeLatest,
    action: onAppStartOrLogin,
  },
  {
    key: APP_START_OR_LOGIN,
    sagasFn: takeLatest,
    action: onAppStartOrLogin,
  },
]
