import { fork } from 'redux-saga/effects'
import { partnerSaga } from './partner'
import { checkLocalPartnerRequest } from './partner/actions'
import { checkLocalPartner } from './partner/operations'
import { userGrantsSagas } from './user-grants'

const preFetchSaga = [
  {
    key: checkLocalPartner,
    sagasFn: fork,
    action: checkLocalPartnerRequest(),
  },
]

export const rootSaga = {
  preFetchSaga,
  partnerSaga,
  userGrantsSagas,
}
