import { Action } from 'types/action'
import {
  GLOBAL_REDUCER_ADD,
  GLOBAL_REDUCER_CLEAN,
  GLOBAL_REDUCER_REMOVE,
} from './action-types'

const defaultState: any = {}

export function globalReducer(state = defaultState, action: Action): any {
  switch (action.type) {
    case GLOBAL_REDUCER_ADD: {
      if (!action.payload) {
        return state
      }

      const {
        payload: { values, type },
      } = action
      return {
        ...state,
        [type]: values,
      }
    }

    case GLOBAL_REDUCER_REMOVE: {
      if (!action.payload) {
        return state
      }

      const {
        payload: { type },
      } = action

      delete state[type]

      return state
    }

    case GLOBAL_REDUCER_CLEAN: {
      return defaultState
    }

    default:
      return state
  }
}
