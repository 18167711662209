// 3rd parties
import axios, { AxiosResponse } from 'axios'
import { jwtDecode } from 'jwt-decode'

// Application
import { getSubdomain } from '../common/subdomain'
import { getCurrentUserContextName } from '../common/utils'
import { partnerActions, partnerServices } from '../ducks/partner'
import { localPartnerService } from '../ducks/partner/services'
import { PartnerJWT } from '../types/partner'
import { API_KEY, APP_API_ROOT, APP_DOCUMENT_API_ROOT } from './constants'

import { correlationIdStorageKey } from '../common/constants'
import { ESCROW_CONTEXTS } from '../common/enums'
import { store } from '../escrow-nacional-root'

const { CancelToken } = axios

const source = CancelToken.source()

const localPartner = localPartnerService()

export const appApi = axios.create({
  baseURL: APP_API_ROOT,
  cancelToken: source.token,
  headers: {
    domain: getSubdomain(),
    'x-api-key': API_KEY,
  },
})

export const documentApi = axios.create({
  baseURL: APP_DOCUMENT_API_ROOT,
  cancelToken: source.token,
  headers: {
    domain: getSubdomain(),
    'x-api-key': API_KEY,
  },
})

documentApi.interceptors.request.use(checkAndRefreshToken as any)
documentApi.interceptors.request.use(verifyIsBackofficeEscrow as any)
documentApi.interceptors.request.use(injectContractIdHeader)

appApi.interceptors.request.use(checkAndRefreshToken as any)
appApi.interceptors.request.use(verifyIsBackofficeEscrow as any)
appApi.interceptors.request.use(injectContractIdHeader)

appApi.interceptors.response.use(persistCorrelationalId)

export function persistCorrelationalId(data: AxiosResponse<any, any>) {
  const correlationId = data.headers?.['x-correlation-id']
  sessionStorage.setItem(correlationIdStorageKey, correlationId || '')
  return data
}

export function verifyIsBackofficeEscrow(config: any) {
  const contextName = getCurrentUserContextName()

  config.headers.isBackoffice = String(
    contextName === ESCROW_CONTEXTS.BACKOFFICE_AUX,
  )

  return config
}

export function injectContractIdHeader(config: any) {
  const { query, body, params, data } = config

  if (!config.headers['x-contract-id']) delete config.headers['x-contract-id']

  const contractId =
    data?.contractId ||
    body?.contractId ||
    query?.contractId ||
    params?.contractId

  if (contractId) {
    config.headers['x-contract-id'] = contractId
  }

  return config
}

export const wait = async (seconds: number) =>
  new Promise(resolve => {
    const timeout = setTimeout(() => {
      clearTimeout(timeout)

      return resolve(true)
    }, 1000 * seconds)
  })

export function authenticating() {
  const { partner }: any = store.getState()

  return partner?.user?.authenticating
}

export async function getNewAuthorization() {
  for (let index = 0; index < 40; index++) {
    // eslint-disable-next-line no-await-in-loop
    await wait(0.5)

    if (!authenticating()) {
      const localAccessToken = localPartner.getCurrent()?.user?.accessToken

      return localAccessToken
        ? `Bearer ${localAccessToken}`
        : appApi.defaults.headers.authorization
    }
  }
}

export async function checkAndRefreshToken(config: any) {
  if (authenticating()) {
    config.headers.authorization = await getNewAuthorization()
  }

  if (isExpiredAccessToken(config.headers.authorization) && !authenticating()) {
    try {
      config.headers.authorization = await refreshToken()
    } catch {
      source.cancel()

      store.dispatch(partnerActions.newAccessTokenFailed())
    }
  }

  if (!config.headers?.authorization) {
    config.headers.authorization = `Bearer ${
      localPartner.getCurrent()?.user?.accessToken
    }`
  }

  return config
}

export async function refreshToken() {
  store.dispatch(partnerActions.expiredAccessToken())

  const responseNewToken = await partnerServices.fetchRefreshToken()
  const { accessToken } = responseNewToken.data

  if (!accessToken) {
    throw new Error('No access token')
  }

  const newAccessToken: string = accessToken

  const authorization = `Bearer ${newAccessToken}`

  appApi.defaults.headers.common.authorization = authorization

  store.dispatch(partnerActions.newAccessTokenComplete(responseNewToken.data))

  return authorization
}

export function isExpiredAccessToken(authorization: string): boolean {
  if (!authorization) {
    return false
  }

  const accessToken = authorization.replace('Bearer ', '')
  const accessTokenDecode: PartnerJWT = jwtDecode(accessToken)

  /**
   * Expira o accessToken 5 min antes do vencimento para prevenir
   * que o mesmo não expire entre a após validação e o antes do request
   **/
  return new Date(Date.now() + 300_000).getTime() > accessTokenDecode.exp * 1000
}
