export * from './app-api'
export * from './auth-api'

export type HandleResponseProps<T = any> = {
  hasError: boolean
  data: T
  error: any
  message: string
  status: any
  errorHandled?: boolean
}

const getDefaultsResponse = (result: any, hasError = false) => ({
  hasError,
  data: result.data || null,
  error: result?.error,
  message: '',
  status:
    result?.status ||
    result.request?.status ||
    result.response?.status ||
    result.request?.statusCode ||
    result.response?.statusCode,
})

export const handleErrorResponse = (result: any): HandleResponseProps => {
  const shortData = result.response?.data
  const message = shortData?.error || shortData?.message || null
  const hasError = result.isAxiosError && result?.code !== 'ERR_CANCELED'
  return {
    ...getDefaultsResponse(result, hasError),
    message,
    errorHandled: true,
  }
}

export const handleSuccessResponse = <T = any>(
  result: any,
): HandleResponseProps<T> => ({
  ...getDefaultsResponse(result),
  hasError: !result.data,
})
