import { call, put } from 'redux-saga/effects'

import { fetchUserGrantsError, fetchUserGrantsSuccess } from './actions'

import { fetchUserGrantsService } from './services'

import { ROLES } from '@escrow/types-national/lib/roles'
import { ESCROW_CONTEXTS } from '../../common/enums'
import { getSubdomain } from '../../common/subdomain'
import { SAVE_ROLES } from '../../ducks/partner/action-types'
import { store } from '../../escrow-nacional-root'
import { Action } from '../../types/action'

export interface ResponseGenerator {
  role?: ROLES
  name?: string
  contractRoles?: any
  profiles: any
}

export function* getUserGrants(action: Action) {
  if (
    !Object.values(ESCROW_CONTEXTS).some(context =>
      window.location.pathname.includes(context),
    )
  ) {
    return
  }

  try {
    const response: ResponseGenerator = yield call(
      fetchUserGrantsService,
      action?.payload,
    )

    store.dispatch({
      type: SAVE_ROLES,
      payload: {
        role: response?.role,
        name: getSubdomain(),
        userName: response?.name,
        contractRoles: response?.contractRoles,
        profiles: response?.profiles,
      },
    })

    if (response) yield put(fetchUserGrantsSuccess(response))
  } catch (err) {
    yield put(fetchUserGrantsError())
  }
}
