import { call, put, select } from 'redux-saga/effects'
import { appApi } from '../../apis'
import { getSubdomain } from '../../common/subdomain'
import { checkStatus, formatDate } from '../../common/utils'
import { Action } from '../../types/action'
import {
  LoginAction,
  PartnerParameters,
  PartnerState,
  PartnerUser,
  PartnerUserPayload,
  PartnerUserRole,
} from '../../types/partner'
import * as userGrantsActions from '../user-grants/actions'

import { ESCROW_CONTEXTS } from '../../common/enums'
import * as actions from './actions'
import { getPartnerIdentity, getUser } from './selectors'
import * as partnerServices from './services'

const mixpanelIdentifySessionKey = 'mixpanelIdentified'
const DISMISSED_ALERTS_STORAGE_KEY = 'dismissed-maturity-alerts'

const localPartner = partnerServices.localPartnerService()

export function* checkLocalPartner(action: Action): any {
  yield put(actions.checkLocalPartnerRequest())

  const partner = localPartner.getCurrent()

  if (!partner) {
    yield put(actions.checkLocalPartnerNotFound())

    return null
  }

  yield put(actions.checkLocalPartnerFound(partner.identity))

  return action
}

export function* fetchLogin(action: LoginAction): any {
  try {
    const { data: login } = yield call(
      partnerServices.loginUserService as any,
      { ...action.payload },
    )

    const { roles, warning, channels } = login

    const { message, statusActive } = checkStatus(roles?.status || 'active')

    login.email = action.payload.loginValues?.username

    const contexts = roles?.contexts

    const channelActive = channels?.some(
      (ch: PartnerUserRole) =>
        ch?.status === 'active' &&
        ch?.contexts?.some(context => context?.status === 'active'),
    )

    if (contexts?.length) {
      localStorage.setItem(
        'context',
        contexts[0].context === ESCROW_CONTEXTS.PORTAL && contexts[0].context,
      )
    }

    const now = new Date()
    const formattedDate = formatDate(now)

    localStorage.setItem('lastTimeClosedPage', formattedDate)

    if (warning) {
      yield put(actions.loginUserWarning(login))
    } else if (!channelActive) {
      yield put(actions.loginUserError(message || null))
    } else if (statusActive || channelActive) {
      yield put(actions.loginUserSuccess(login))
    } else if (!contexts?.length) {
      yield put(actions.loginUserError(message || null))
    } else if (statusActive) {
      yield put(actions.loginUserSuccess(login))
    } else {
      yield put(actions.loginUserError(message || null))
    }
  } catch (error) {
    const err: any = error
    yield put(actions.loginUserError(err?.response?.data?.error || null))
  }
}

export function* saveLocalPartnerUser(action: PartnerUserPayload): any {
  const { payload } = action

  const partnerUser: PartnerUser = {
    ...payload,
  }

  Reflect.deleteProperty(partnerUser, 'roles')

  const identity = yield select(getPartnerIdentity)

  const partner: PartnerState = {
    identity,
    user: partnerUser,
  }

  localPartner.updateCurrent(partner)

  yield put(actions.saveLocalPartnerUser(payload))
}

export function* localPartnerUser(): any {
  yield put(actions.localUserRequest())

  const partner = localPartner.getCurrent()

  if (partner?.user?.authenticated) {
    const user = {
      ...partner.user,
      roles: null,
    }

    return yield put(actions.userAlreadyLogged(user))
  }

  yield put(actions.userNotLogged())
}

export const removeBatchLocalStorage = keyToSearchCommon => {
  const itemsLocalStorage: any = localStorage

  let keysToConsist = Object.keys(itemsLocalStorage)
    .filter(key => key.includes(keyToSearchCommon))
    .map(key => ({
      key,
      item: localStorage.getItem(key),
    }))

  keysToConsist.forEach(item => {
    localStorage.removeItem(item.key)
  })

  return keysToConsist
}

export function* fetchLogoutUser(action: Action): any {
  const actionPayload = action?.payload || {}
  try {
    const { user } = localPartner.getCurrent() ?? {}
    yield call(partnerServices.logoutUserService, { ...user, ...actionPayload })

    yield put(actions.logoutUserComplete(action?.payload))

    return action
  } catch (error) {
    yield put(actions.logoutUserFailed(action?.payload))

    return error
  } finally {
    localPartner.setCurrent({
      identity: localPartner.getCurrent()?.identity,
    })

    const maturityDismissedBannersToPersist = removeBatchLocalStorage(
      DISMISSED_ALERTS_STORAGE_KEY,
    )

    localStorage.clear()
    sessionStorage.clear()

    sessionStorage.setItem(mixpanelIdentifySessionKey, 'false')

    let keysToConsistBlipSession = removeBatchLocalStorage('blip-session')
    let keysToConsistwalkthrough = removeBatchLocalStorage('walkthrough')
    let keysToConsistBlipOpened = removeBatchLocalStorage('openedFirstTime')
    let keysToConsistDefaultSystem = removeBatchLocalStorage('default-system')

    keysToConsistBlipSession.forEach(data =>
      localStorage.setItem(data.key, data.item as any),
    )

    keysToConsistBlipOpened.forEach(data =>
      localStorage.setItem(data.key, data.item as any),
    )

    keysToConsistwalkthrough.forEach(data =>
      localStorage.setItem(data.key, data.item as any),
    )

    keysToConsistDefaultSystem.forEach(data =>
      localStorage.setItem(data.key, data.item as string),
    )

    maturityDismissedBannersToPersist.forEach(data =>
      localStorage.setItem(data.key, data.item as string),
    )

    yield put(userGrantsActions.clearUserGrants())
  }
}

export function* newAccessTokenRequest() {
  yield put(actions.newAccessTokenRequest())
}

export function newAccessTokenFailed() {
  localPartner.removeUser()
  window.location.href = '/'
}

export function* putAppApiAuthorization(action: Action): any {
  const user = { ...(yield select(getUser)), ...action.payload }

  localPartner.updateCurrent({ user })

  appApi.defaults.headers.authorization = `Bearer ${user.accessToken}`
}

export function* fetchUserInfo(): any {
  const defaultRole = (subdomain: string) => ({
    status: 'active',
    role: 'guest',
    name: subdomain,
  })

  const subdomain = getSubdomain()

  try {
    yield put(actions.setLoading(true))

    const userInfo = yield call(partnerServices.fetchUserInfo)

    const channels = userInfo?.channels

    const role = channels
      ? channels.find((channel: any) => channel?.name === subdomain) ||
        defaultRole(subdomain)
      : defaultRole(subdomain)

    yield put(actions.updateRoles(role))

    return role
  } catch (error) {
    yield put(actions.updateRoles(defaultRole(subdomain)))

    return error
  } finally {
    yield put(actions.setLoading(false))
  }
}

export function* onAppStartOrLogin(): Generator<any> {
  if (window.location.pathname.includes('/entrar')) {
    return
  }

  try {
    const userInfo: any = yield call(fetchUserInfo)

    const partnerParameters: PartnerParameters = userInfo

    return yield put(actions.setPartnerParametersComplete(partnerParameters))
  } catch (error) {
    yield put(actions.setPartnerParametersFailed())

    return error
  }
}

export function* updatePartnerParameters(action: Action): any {
  try {
    yield call(partnerServices.updatePartnerParameters, action.payload)

    yield put(actions.updatePartnerParametersComplete(action?.payload))

    return action
  } catch (error) {
    yield put(actions.updatePartnerParametersFailed())

    return error
  }
}
