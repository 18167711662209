// 3rd parties
import { createSelector } from 'reselect'

// Application
import { PartnerState } from 'types/partner'

export type AppState = {
  partner: PartnerState
}

const partnerSelector = (state: AppState): PartnerState => state.partner

export const getPartner = createSelector(partnerSelector, state => state)

export const getPartnerIdentity = createSelector(
  partnerSelector,
  state => state.identity,
)

export const getUser = createSelector(partnerSelector, state => state.user)

export const getPartnerParameters = createSelector(
  partnerSelector,
  state => state?.partnerParameters,
)

export const getSystemParameters = createSelector(
  partnerSelector,
  state => state?.systemParameters,
)
