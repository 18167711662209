// 3rd parties
import axios from 'axios'

// Local
import { getSubdomain } from '../common/subdomain'
import { checkAndRefreshToken } from './app-api'
import { APP_API_ROOT } from './constants'

export const proxyAuthApi = (accessToken?: string, options?: any) => {
  const finalOptions = { refresh: false, ...options }

  const config: any = {
    baseURL: `${APP_API_ROOT}/escrow/security`,
    headers: { domain: getSubdomain() },
  }

  if (accessToken) {
    config.headers.authorization = `Bearer ${accessToken}`
  }

  const instance = axios.create(config)
  if (finalOptions.refresh) {
    instance.interceptors.request.use(checkAndRefreshToken)
  }

  return instance
}
