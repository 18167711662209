import { registerApplication, start } from 'single-spa'
import {
  constructApplications,
  constructLayoutEngine,
  constructRoutes,
} from 'single-spa-layout'
import { configureStore } from './ducks/configure-store'
import { actionTypes, selector } from './ducks/global'
import microfrontendLayout from './microfrontend-layout.html'

// @ts-ignore
import { StyleGuideComponents } from '@escrow/nacional-styleguide'
import { appApi, documentApi } from './apis'
import {
  partnerActions,
  partnerActionTypes,
  partnerOperations,
  partnerSelectors,
  partnerServices,
} from './ducks/partner'
import {
  userGrantsActions,
  userGrantsActionTypes,
  userGrantsSelectors,
} from './ducks/user-grants'

export const { store, sagasInjector, reducerInjector } = configureStore()

const data = {
  props: {
    globalRedux: {
      store,
      selector,
      actionTypes,
      sagasInjector,
      reducerInjector,
      ducks: {
        partner: {
          actions: partnerActions,
          actionTypes: partnerActionTypes,
          operations: partnerOperations,
          services: partnerServices,
          selectors: partnerSelectors,
        },
        userGrants: {
          actions: userGrantsActions,
          actionTypes: userGrantsActionTypes,
          selectors: userGrantsSelectors,
        },
      },
      apis: {
        app: appApi,
        document: documentApi,
      },
    },
  },
  loaders: {
    default: StyleGuideComponents.LoadingLazyPage(),
  },
}

const routes = constructRoutes(microfrontendLayout, data)

const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name)
  },
})
const layoutEngine = constructLayoutEngine({
  routes,
  applications,
  active: false,
})

applications.forEach(registerApplication)

System.import('@escrow/nacional-styleguide').then(() => {
  // Activate the layout engine once the styleguide CSS is loaded
  layoutEngine.activate()
  start({
    urlRerouteOnly: false,
  })
})
