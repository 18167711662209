import { takeLatest } from 'redux-saga/effects'

import { GET_USER_GRANTS } from './action-types'

import { getUserGrants } from './operations'

export const userGrantsSagas = [
  {
    key: GET_USER_GRANTS,
    sagasFn: takeLatest,
    action: getUserGrants,
  },
]
