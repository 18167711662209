import { ROLES } from '@escrow/types-national/lib/roles'
import { ESCROW_CONTEXTS, USER_STATUS_TYPE } from 'common/enums'
import * as actionTypes from './action-types'

export const fetchUserGrants = (contractId?: string) => ({
  type: actionTypes.GET_USER_GRANTS,
  payload: { contractId },
})

export const clearUserGrants = () => ({
  type: actionTypes.CLEAR_USER_GRANTS,
})

type SetIdentifyContextProps = {
  success: boolean
  channelContext: {
    context: ESCROW_CONTEXTS
    role: ROLES
    status: USER_STATUS_TYPE
  }
}

export const setIdentifyContext = (payload: SetIdentifyContextProps) => ({
  type: actionTypes.SET_IDENTIFY_CONTEXT,
  payload,
})

type FetchUserGrantsSuccessProps = {
  grants?: Array<{
    role: ROLES
    resource: string[]
    action: string[]
    attributes: string[]
  }>
  role?: ROLES
  profiles?: ROLES[]
}

export const fetchUserGrantsSuccess = (
  payload: FetchUserGrantsSuccessProps,
) => ({
  type: actionTypes.GET_USER_GRANTS_SUCCESS,
  payload,
})

export const fetchUserGrantsError = () => ({
  type: actionTypes.GET_USER_GRANTS_FAILED,
})
