import { ESCROW_CONTEXTS } from './enums'

export const getCurrentUserContextName = () => {
  const mapContext: any = {
    BACKOFFICE: ESCROW_CONTEXTS.BACKOFFICE_AUX,
    BACKOFFICE_AUX: ESCROW_CONTEXTS.BACKOFFICE_AUX,
    PORTAL: ESCROW_CONTEXTS.PORTAL,
  }

  const [contextKey] =
    Object.entries(ESCROW_CONTEXTS).find(([_key, ctx]) =>
      window.location.pathname.includes(ctx),
    ) || []

  if (!contextKey) {
    return null
  }

  return mapContext[contextKey]
}

const STATUS: any = {
  inactive: 'Seu usuário está Inativo',
  suspended: 'Seu usuário está suspenso',
  block: 'Seu usuário está bloqueado',
}

export const checkStatus = (access: string) => {
  const statusActive = access === 'active'
  return {
    statusActive,
    message: STATUS[access],
  }
}
export const formatDate = (date: Date): string => {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')

  const hours = String(date.getHours()).padStart(2, '0')
  const minutes = String(date.getMinutes()).padStart(2, '0')
  const seconds = String(date.getSeconds()).padStart(2, '0')

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
}
